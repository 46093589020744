@keyframes animated-block {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.recording-indicator {
    width: 10px;
    height: 10px;
    margin-right: 0.5rem;
    border-radius: 50%;
    background-color: #099fff;
    animation-name: animated-block;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
